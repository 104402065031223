@tailwind base;
@tailwind components;
@tailwind utilities;

/* Style for the modal itself */
.modalAddEdit {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    z-index: 1000; /* Ensure it's on top */
  }
  
  /* Style for the overlay */
  .overlayAddEdit {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure it's under the modal */
  }
  


.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-radius: var(--ck-border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: 224px;
}

.container {
  padding: 0px !important;
  max-width: 100% !important;
}

.expert-image-container .text-gray-600,
.expert-video-container .text-gray-600
{
  background-color:#eee !important;
}

.expert-image-container .cursor-pointer,
.expert-video-container .cursor-pointer
{
margin-top: 5%;
}
.overflow-auto-imp{
  overflow: auto !important;
}
.bg-gray-100-dark{
  background-color: rgb(219 224 234);
}