.bg_image{
    background-image: url(../public/images/landing/landing_speaker_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: bottom; */
}

.login-form-container-operator{
  background-image: url('../public/images/landing/login_bg.png');
  background-repeat: no-repeat;
  background-size: cover; 
  /* background-position: bottom; */
}
.login-form-container{
  background-image: url('../public/images/landing/login_bg.png');
  background-repeat: no-repeat;
  background-size: cover; 
  /* background-position: bottom; */
}
.landingImage{
  background-image: url('../public/images/landing/landing_mic.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;
}
/* section 
{
overflow: unset !important;
overflow: auto !important;
position: absolute !important;
width: 100vw !important;
} */
 .border-custom-b{
  border-bottom: 1.5px solid;
 }
.custom-shadow{
  box-shadow: 1px 1px 2px 1px rgb(200, 199, 199);
}

.icon-img-custom{
  height: 18px !important;
  text-align: center !important;
}